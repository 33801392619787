import { template as template_741cdba47bbf454e9fc59b3165aaf932 } from "@ember/template-compiler";
const SidebarSectionMessage = template_741cdba47bbf454e9fc59b3165aaf932(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
