import { template as template_b62b50bdc63a4dbcb941b9fa82d43fd6 } from "@ember/template-compiler";
const FKLabel = template_b62b50bdc63a4dbcb941b9fa82d43fd6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
