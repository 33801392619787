import { template as template_3b798528f04d4cc082d61e117f0bd115 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_3b798528f04d4cc082d61e117f0bd115(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
