import { template as template_cb3fae92ea9c44379e781e8d2e67ac56 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_cb3fae92ea9c44379e781e8d2e67ac56(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
