import { template as template_a85a545668c34195b225f13b1c717f55 } from "@ember/template-compiler";
const WelcomeHeader = template_a85a545668c34195b225f13b1c717f55(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
