import { template as template_79fb006f2c9d4d5d8dc139fb1fab67ed } from "@ember/template-compiler";
import FKText from "discourse/form-kit/components/fk/text";
const FKFieldset = template_79fb006f2c9d4d5d8dc139fb1fab67ed(`
  <fieldset name={{@name}} class="form-kit__fieldset" ...attributes>
    {{#if @title}}
      <legend class="form-kit__fieldset-title">{{@title}}</legend>
    {{/if}}

    {{#if @description}}
      <FKText class="form-kit__fieldset-description">
        {{@description}}
      </FKText>
    {{/if}}

    {{yield}}
  </fieldset>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKFieldset;
